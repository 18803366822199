'use client';
import Link from 'next/link';
import { Menu, X } from 'lucide-react';
import { useState } from 'react';
import { SignInButton, SignedIn, SignedOut, UserButton } from '@clerk/nextjs';

export function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { href: '/record', label: 'Record' },
    { href: '/settings', label: 'Settings' },
  ];

  return (
    <header className='sticky top-0 z-50 w-full border-b bg-white/80 backdrop-blur-sm'>
      <div className='container mx-auto px-4 md:px-6'>
        <div className='flex h-20 items-center justify-between'>
          <div className='flex items-center gap-2'>
            <Link href='/' className='text-2xl font-bold text-green-600'>
              TalkTranslate
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className='hidden md:flex gap-6'>
            <SignedIn>
              {navItems.map((item) => (
                <Link
                  key={item.href}
                  href={item.href}
                  className='text-sm font-medium text-gray-600 hover:text-green-600 transition-colors'
                >
                  {item.label}
                </Link>
              ))}
            </SignedIn>
            <SignedOut>
              <SignInButton mode='modal'>
                <button className='bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md transition-colors'>
                  Sign In
                </button>
              </SignInButton>
            </SignedOut>
            <SignedIn>
              <UserButton
                appearance={{
                  elements: {
                    userButtonAvatarBox: 'w-10 h-10',
                  },
                }}
              />
            </SignedIn>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className='md:hidden p-2'
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <>
                <SignedOut>
                  <SignInButton mode='modal'>
                    <button className='bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md transition-colors'>
                      Sign In
                    </button>
                  </SignInButton>
                </SignedOut>
                <SignedIn>
                  <UserButton
                    appearance={{
                      elements: {
                        userButtonAvatarBox: 'w-10 h-10',
                      },
                    }}
                  />
                </SignedIn>
              </>
            ) : (
              <Menu className='h-6 w-6' />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <div className='md:hidden bg-white border-t border-gray-200'>
          <nav className='flex flex-col p-4'>
            <SignedIn>
              {navItems.map((item) => (
                <Link
                  key={item.href}
                  href={item.href}
                  className='block py-2 text-sm font-medium text-gray-600 hover:text-green-600 transition-colors'
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </Link>
              ))}
            </SignedIn>
          </nav>
        </div>
      )}
    </header>
  );
}
